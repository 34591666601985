var currCulture = 'vi';
if (window.location.href.indexOf("en-US") > 0)
    currCulture = "en";
else
    currCulture = "vi";
var lang = {};
lang['en'] = {
    'CopyLink': 'Copied',
};
lang['vi'] = {
    'CopyLink': 'Đã copy',
};

jQuery(function () {
    $("header").sticky({
        topSpacing: 0,
        zIndex: 999,
    });

    $(".mega-toggle-mobile").on("click", function () {
        $(this).prev().toggleClass("show");
    });

    if ($(window).width() < 1024) {
        $(".mega-menu").each(function () {
            $(this).prepend(
                "<div class='mega-close'><span class='ri-close-line'></span></div>"
            );
        });
    }

    $(document).on("click", ".mega-close", function () {
        $(this).parent().removeClass("show");
    });

    var myOffside = offside("#mobileMenu", {
        slidingElementsSelector: "body",
        buttonsSelector: ".menu-toggle, .offcanvas-close, .offcanvas-overlay",
        beforeOpen: function () {
            $(".offcanvas-overlay").fadeIn();
        },
        beforeClose: function () {
            $(".offcanvas-overlay").fadeOut();
        },
    });

    $.fancybox.open($(".popup-promo"));

    if ($(".popup-promo-wrapper").length > 0) {
        $(".popup-form-wrapper").append($(".form-in-popup-wrapper"));
    }

    if ($(".firstnav").length == 0) {
        $(".account .dropdown-content").remove();
    }

    $(".footer-dealer-toggle a").on("click", function (e) {
        e.preventDefault();
        $(".footer-dealer-list").slideToggle();
    });

    if ($(".product-detail-comment-section").length > 0) {
        $(".product-detail-comment-section .product-detail-comment").append(
            $(".commentpanel")
        );
    }

    $(".room-heading-section .article-content").height(
        $(".room-heading-section .img").height()
    );

    $(".btn-comment").on("click", function (e) {
        e.preventDefault();
        $(".comment-form").slideToggle();
    });

    $(".cart-toggle").on("click", function () {
        $(".cart-dropdown").toggleClass("show");
    });

    if ($(".checkout-button").length > 0) {
        $(".checkout-button").append($(".cart-group-button"));
    }

    $(window).resize(function () {
        $(".room-heading-section .article-content").height(
            $(".room-heading-section .img").height()
        );
    });

    $(document).on("click", function (e) {
        if (
            $(".cart-dropdown").has(e.target).length === 0 &&
            $(".cart-toggle").has(e.target).length === 0
        ) {
            $(".cart-dropdown").removeClass("show");
        }

        if (
            $(".productsearchbox").has(e.target).length === 0 &&
            $(".suggestsearch").has(e.target).length === 0
        ) {
            $(".suggestsearch").remove();
            $(".searchinput").val("");
        }
    });

    if ($(".news-col.show").length == $(".news-col").length) {
        $(".room-inspiration-section .btn-view-more-square").remove();
    } else {
        $(".room-inspiration-section .btn-view-more-square").on(
            "click",
            function () {
                $(".news-col.show")
                    .last()
                    .nextAll()
                    .slice(0, 3)
                    .addClass("show");
                if ($(".news-col.show").length == $(".news-col").length) {
                    $(
                        ".room-inspiration-section .btn-view-more-square"
                    ).remove();
                }
            }
        );
    }

    $("#share-social").jsSocials({
        shares: [
            "email",
            "pinterest",
            {
                share: "facebook",
                label: "Share",
            },
        ],
    });

    if($('.firstnav').length > 0){
        $('.account .text').text($('.firstnav span').text())
    }

    $(".copy-link").on("click", function () {
        var dummy = document.createElement("input"),
            text = window.location.href;

        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand("copy");
        $(this).find('span').text(lang[currCulture].CopyLink)
        $(this).addClass('disabled')
        document.body.removeChild(dummy);
    });

    megaMenuHover();
    tabInit();
    bannerHomeInit();
    productSlider();
    homeSolutionSlider();
    filterToggle();
    productDetailSliderInit();
    spinInput();
    roomDetailSliderInit();
    promotionBannerSlideInit();
    headerBannerSlideInit()
    landingBannerSlider()
    collapseInit();
    checkOutJs();
    productDetailTab();
    try {
        countdownInit();
    } catch (error) {}

    if ($(window).width() > 1024) {
        productDetailViewMore();
    }
});

try {
    const menuMapping = new MappingListener({
        selector: ".menu-wrapper",
        mobileWrapper: ".offcanvas-wrap",
        mobileMethod: "appendTo",
        desktopWrapper: ".search-wrapper",
        desktopMethod: "insertBefore",
        breakpoint: 1024.98,
    }).watch();

    const menuTopMapping = new MappingListener({
        selector: ".header-top-wrap",
        mobileWrapper: ".offcanvas-wrap",
        mobileMethod: "appendTo",
        desktopWrapper: ".header-bottom-wrap",
        desktopMethod: "insertBefore",
        breakpoint: 1024.98,
    }).watch();
} catch (error) {}

function megaMenuHover() {
    $(".mega-menu-link a").mouseenter(function () {
        var idHover = $(this).data("catalogue-id");
        $(".mega-menu-catalogue").hide();
        $('.mega-menu-catalogue[data-catalogue-id="' + idHover + '"').show();
    });
}

function collapseInit() {
    $(document).on("click", ".collapse-trigger", function () {
        $(this).toggleClass("opened");
        $(this).next().slideToggle();
        return false;
    });
}

function tabInit() {
    $(".footer-dealer-tab").tabslet({
        animation: true,
    });
}

function bannerHomeInit() {
    const bannerHomeSwiper = new Swiper(
        ".banner-home-list-wrap .swiper-container",
        {
            speed: 1500,
            effect: "fade",
            autoplay: {
                delay: 5000,
            },
            fadeEffect: {
                crossFade: true,
            },
            pagination: {
                el: ".banner-home-list-wrap .swiper-pagination",
                type: "bullets",
                clickable: true,
            },
        }
    );
}

function promotionBannerSlideInit() {
    const promotionBannerSlide = new Swiper(
        ".promotion-banner-slide .swiper-container",
        {
            speed: 1500,
            effect: "fade",
            fadeEffect: {
                crossFade: true,
            },
            pagination: {
                el: ".promotion-banner-slide .swiper-pagination",
                type: "bullets",
                clickable: true,
            },
        }
    );
}

function headerBannerSlideInit() {
    const headerBannerSlide = new Swiper(
        ".header-top-banner .swiper-container",
        {
            speed: 1500,
            spaceBetween: 30,
            loop: true,
            effect: "fade",
            fadeEffect: {
                crossFade: true,
            },
            autoplay: {
                delay: 4000
            }
        }
    );
}

function homeSolutionSlider() {
    const bannerHomeSwiper = new Swiper(
        ".solution-category-list .swiper-container",
        {
            slidesPerView: 2,
            spaceBetween: 20,
            navigation: {
                prevEl: ".solution-category-list .swiper-btn-prev",
                nextEl: ".solution-category-list .swiper-btn-next",
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                },
                576: {
                    slidesPerView: 1,
                },
            },
        }
    );
}

function productSlider() {
    $(".product-slider").each(function (index) {
        var $this = $(this);
        if ($(this).data("slides") !== undefined) {
            var initSlide = $(this).data("slides");
        } else {
            var initSlide = 6;
        }
        $this.addClass("product-slider-" + index);
        $this.find(".swiper-btn-prev").addClass("product-slider-prev-" + index);
        $this.find(".swiper-btn-next").addClass("product-slider-next-" + index);

        var productSlider = new Swiper(
            ".product-slider-" + index + " .swiper-container",
            {
                slidesPerView: initSlide,
                navigation: {
                    prevEl: ".product-slider-prev-" + index,
                    nextEl: ".product-slider-next-" + index,
                },
                breakpoints: {
                    1280: {
                        slidesPerView: 4,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    576: {
                        slidesPerView: 2,
                    },
                },
            }
        );
    });
}

function filterToggle() {
    $(document).on("click", ".product-filter-toggle", function (e) {
        e.preventDefault();
        $(".product-filter-container").slideDown();
        $("body").addClass("overflow-hidden");
    });

    $(document).on(
        "click",
        ".product-filter-container-close, .btn-filter-comfirm",
        function (e) {
            e.preventDefault();
            $(".product-filter-container").slideUp();
            $("body").removeClass("overflow-hidden");
        }
    );
}

function productDetailSliderInit() {
    const productDetailThumb = new Swiper(
        ".product-detail-section .product-detail-thumbnail .swiper-container",
        {
            slidesPerView: 6,
            spaceBetween: 15,
            observer: true,
            observeParents: true,
            breakpoints: {
                768: {
                    slidesPerView: 4,
                },
            },
        }
    );
    const productDetailImage = new Swiper(
        ".product-detail-section .product-detail-images .swiper-container",
        {
            observer: true,
            observeParents: true,
            navigation: {
                prevEl: ".product-detail-images .swiper-btn-prev",
                nextEl: ".product-detail-images .swiper-btn-next",
            },
            thumbs: {
                swiper: productDetailThumb,
            },
        }
    );
}

function roomDetailSliderInit() {
    const roomDetailThumb = new Swiper(
        ".room-detail-section .product-detail-thumbnail .swiper-container",
        {
            slidesPerView: 5,
            spaceBetween: 15,
            observer: true,
            observeParents: true,
        }
    );
    const roomDetailImage = new Swiper(
        ".room-detail-section .product-detail-images .swiper-container",
        {
            observer: true,
            observeParents: true,
            navigation: {
                prevEl: ".product-detail-images .swiper-btn-prev",
                nextEl: ".product-detail-images .swiper-btn-next",
            },
            thumbs: {
                swiper: roomDetailThumb,
            },
        }
    );
    const roomOtherDetailSlider = new Swiper(
        ".room-other-model .swiper-container",
        {
            slidesPerView: 1.5,
            spaceBetween: 30,
        }
    );
}

function landingBannerSlider() {
    $(".landing-page-banner-slider").each(function (index) {
        var $this = $(this);
        $this.addClass("landing-page-banner-slider-" + index);
        $this.find(".swiper-pagination").addClass("landing-page-banner-slider-pagination-" + index);
        var heroGallerySlider = new Swiper(
            ".landing-page-banner-slider-" + index + " .swiper-container",
            {
                slidesPerView: 1,
                effect: "fade",
                fadeEffect: {
                    crossFade: true,
                },
                autoplay: {
                    delay: 4000
                },
                loop: true,
                pagination: {
                    el: ".landing-page-banner-slider-pagination-" + index,
                    clickable: true
                },
                breakpoints: {
                    1280: {
                        spaceBetween: 75,
                    },
                    1024: {
                        spaceBetween: 20,
                    },
                    768: {
                        spaceBetween: 10,
                    },
                },
            }
        );
    });
}

function productDetailViewMore() {
    $(".product-detail-view-more").on("click", function (e) {
        e.preventDefault();
        $(this).siblings(".desc-content").toggleClass("expanded");
    });

    if ($(".product-detail-desc-content .article-content").height() <= 570) {
        $(".product-detail-desc-content .product-detail-view-more").remove();
    }

    if ($(".product-detail-spec .article-content").height() <= 610) {
        $(".product-detail-spec .product-detail-view-more").remove();
    }
}

function checkOutJs() {
    $(".issueInvoice").on("click", function () {
        $(".issue-invoice-form").slideToggle();
    });
}

function countdownInit() {
    $(".deal-deadline").each(function () {
        var date = $(this).data("countdown");
        var formatDate = parseDate(date);
        $(this).countdown(formatDate, function (event) {
            $(this).html(event.strftime("%-D ngày %H:%M:%S"));
        });
    });

    $(".flashsale-countdown").each(function () {
        var date = $(this).data("countdown");
        var formatDate = parseDate(date);
        $(this).countdown(formatDate, function (event) {
            $(this).html(
                event.strftime(
                    "<span class='number'>%-D</span> : " +
                        "<span class='number'>%H</span> : " +
                        "<span class='number'>%M</span> : " +
                        "<span class='number'>%S</span>"
                )
            );
        });
    });
}

function productDetailTab() {
    $(".product-detail-desc-title").on("click", function () {
        var index = $(this).data("tab");
        $(this).siblings().removeClass("active");
        $(this).addClass("active");
        $(".product-detail-tab-content").removeClass("show");
        $(".product-detail-tab-content")
            .filter("[data-tab='" + index + "']")
            .addClass("show");
    });
}

function spinInput() {
    $(".spin-btn").click(function () {
        var $button = $(this);
        var type = $(this).data("spin");
        var oldValue = $button.parent().find("input").val();

        if (type == "inc") {
            var newVal = parseFloat(oldValue) + 1;
        } else {
            if (oldValue > 0) {
                var newVal = parseFloat(oldValue) - 1;
            } else {
                newVal = 0;
            }
        }
        $button.parent().find("input").val(newVal);
        AjaxCart.updatecart();
    });
}

function parseDate(date) {
    var dateInit = date;
    var datearray = dateInit.split(" ");
    var dateNeedFormat = datearray[0];
    var dateNeedFormatArray = dateNeedFormat.split(/\//);
    var dateAfterFormat = [
        dateNeedFormatArray[1],
        dateNeedFormatArray[0],
        dateNeedFormatArray[2],
    ].join("/");
    return [dateAfterFormat, datearray[1]].join(" ");
}
